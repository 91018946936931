import React from "react";
import { FaInstagram } from "react-icons/fa";
import { Box, Heading, Icon, IconButton } from "@chakra-ui/react";

const igIcon = <Icon></Icon>;
export const App = () => (
  <Box textAlign="center">
    <Heading as="h1" size="2xl">
      Hi! It's K9 Kerri
    </Heading>
    <Heading as="h3" size="l">
      Work in progress. For now, checkout my instagram !
    </Heading>

    <IconButton
      as="a"
      aria-label="K9s with Kerri Instagram Link"
      variant="link"
      fontSize="9xl"
      href="https://instagram.com/k9swithkerri"
      icon={<FaInstagram />}
    />
  </Box>
);
